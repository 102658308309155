import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import type { ComponentType } from 'react'
import type { IHeaderHome } from '../../molecules/HeaderHome'
import type { IHeaderPage } from '../../molecules/HeaderPage'
import type { IHeaderVideo } from '../../molecules/HeaderVideo'

import { useContext, useMemo } from 'react'
import dynamic from 'next/dynamic'

import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { MenuTabBar } from '../../atoms/MenuNavBar'
import { DownloadBanner } from '../../molecules/DownloadBanner'
import { DownloadBannerContextWrapper } from '../../../contexts/DownloadBanner'

const HeaderHome: ComponentType<IHeaderHome> = dynamic(function HeaderHome() {
  return import('../../molecules/HeaderHome').then((module) => module.HeaderHome)
})
const HeaderPage: ComponentType<IHeaderPage> = dynamic(function HeaderPage() {
  return import('../../molecules/HeaderPage').then((module) => module.HeaderPage)
})
const HeaderVideo: ComponentType<IHeaderVideo> = dynamic(function HeaderVideo() {
  return import('../../molecules/HeaderVideo').then((module) => module.HeaderVideo)
})

export function Header({
  sponsor,
  eventLogo,
  navigation,
  downloadBanner,
  outdatedBanner,
  topic,
  wordings,
}: BlockTypes['header-navigation']['data']): JSX.Element {
  const { type, subtype } = useContext(ApiResponseContext)

  const headerType = useMemo(() => {
    if (subtype === 'home') return <HeaderHome wordings={wordings} navigation={navigation} />

    if (type === 'content' && subtype === 'video') {
      return <HeaderVideo topic={topic} />
    }

    return (
      <HeaderPage
        eventLogo={eventLogo}
        sponsor={sponsor}
        outdatedBanner={outdatedBanner}
        topic={topic}
        wordings={wordings}
        navigation={navigation}
      />
    )
  }, [subtype])

  return (
    <>
      <DownloadBannerContextWrapper enabled={!!downloadBanner}>
        {downloadBanner ? <DownloadBanner {...downloadBanner} /> : null}
        {navigation?.length ? <MenuTabBar elementList={navigation} /> : null}
        {headerType}
      </DownloadBannerContextWrapper>
    </>
  )
}
