import { useState } from 'react'
import { useAmp } from 'next/amp'
import { Link } from '../../atoms/Link'
import { stringToId } from '../../../helpers/stringToId'
import { Topic } from '../../atoms/Topic'
import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { SVGIcon } from '../../atoms/SVGIcon'
import { theme } from '../../../styles'

export interface IMenuTopics {
  title: string
  elementList: Array<{ title: string; link: string; color: string }>
  more: string
}

export function MenuTopics({ title, elementList, more }: IMenuTopics): JSX.Element {
  const isAmp = useAmp()
  const [isActive, setIsActive] = useState(true)

  const { menuHit } = useTMSHitForMenuBurger()
  const dropMenuId = `drop-topics-${stringToId(title)}`

  const handleOnclick = () => {
    menuHit()
    setIsActive(!isActive)
  }

  const propsAmp = {
    on: `tap:${dropMenuId}.toggle`,
  }

  const RenderList = function () {
    if (!elementList?.length) return null
    return (
      <>
        {elementList?.map(({ title: categoryName, link, color }, index) => (
          <li key={index} className="MenuTopics__Item" onClick={() => menuHit(title, categoryName)}>
            <Topic link={link} color={color} title={categoryName} />
          </li>
        ))}
      </>
    )
  }
  return (
    <>
      <div className="MenuTopics">
        {!isAmp && (
          <button
            className={`MenuTopics__Dropdown${
              isActive ? ' MenuTopics__Dropdown--active' : ''
            } flex`}
            onClick={handleOnclick}
            aria-expanded={isActive}
            aria-controls={dropMenuId}
          >
            <div className="MenuTopics__Chevron">
              <SVGIcon name="chevron" orientation="up" />
            </div>
            <div className="MenuTopics__Title">{title}</div>
          </button>
        )}
        {isAmp ? (
          <amp-accordion id={dropMenuId}>
            <section>
              <h2 className="MenuTopics__Accordion__Header">
                <button className="MenuTopics__Dropdown flex" {...propsAmp}>
                  <div className="MenuTopics__Chevron">
                    <SVGIcon name="chevron" orientation="up" />
                  </div>
                  <div className="MenuTopics__Title">{title}</div>
                </button>
              </h2>
              <ul id={dropMenuId} className="MenuTopics__List flex-column">
                {RenderList()}
                <li>
                  <Link url="/sujets/" onClick={() => menuHit(title, more)}>
                    <span className="MenuTopics__More">{more}</span>
                  </Link>
                </li>
              </ul>
            </section>
          </amp-accordion>
        ) : (
          <ul id={dropMenuId} className="MenuTopics__List flex-column">
            {RenderList()}
            <li>
              <Link url="/sujets/" onClick={() => menuHit(title, more)}>
                <span className="MenuTopics__More">{more}</span>
              </Link>
            </li>
          </ul>
        )}
      </div>

      <style jsx>{`
        #${dropMenuId}:checked + .MenuTopics__Dropdown > .MenuTopics__Chevron :global(> svg),
        .MenuTopics__Dropdown--active > .MenuTopics__Chevron :global(> svg),
        h2[aria-expanded='true'] .MenuTopics__Chevron :global(> svg) {
          transform: rotate(90deg);
        }

        #${dropMenuId}:checked + label + .MenuTopics__List,
        .MenuTopics__Dropdown--active + .MenuTopics__List,
        h2[aria-expanded='true'] .MenuTopics__List {
          display: flex;
          visibility: visible;
        }
      `}</style>
      <style jsx>{`
        .MenuTopics {
          width: 100%;
          padding: 0 0 60px;
        }

        .MenuTopics__Dropdown {
          width: 100%;
          text-align: left;
          cursor: pointer;
          border: none;
          background: transparent;
          padding: 37px 30px 37px;
        }

        .MenuTopics__Accordion__Header {
          border: none;
          background-color: transparent;
        }

        .MenuTopics__Dropdown :global(svg) {
          transition: transform 0.3s ease;
          margin-right: 4px;
        }

        .MenuTopics__Title {
          font-family: ${theme.cssVars.overpass};
          font-size: 30px;
          font-weight: 700;
          line-height: 116%;
          color: ${theme.cssVars.deepBlue};
          margin: 0;
        }

        .MenuTopics__List {
          display: ${isAmp ? 'flex' : 'none'};
          visibility: ${isAmp ? 'visible' : 'hidden'};
          list-style: none;
          margin: 0;
          padding: 0 4px;
          gap: 4px;
        }

        .MenuTopics__More {
          display: block;
          margin: 30px 30px 0 30px;
          font-family: ${theme.cssVars.overpass};
          font-weight: 800;
          font-size: 17px;
          line-height: 141%;
          text-decoration-line: underline;
          color: ${theme.cssVars.deepBlue};
        }
      `}</style>
    </>
  )
}
