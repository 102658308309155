import { useContext } from 'react'
import { useRouter } from 'next/router'
import slugify from 'slugify'
import { FacilitiButton } from '../FacilitiButton'
import { TF1InfoLogo } from '../Icon/TF1InfoLogo'
import { Link } from '../Link'
import { isPathHomeForYou } from '../../../helpers/client'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { theme, mixins } from '../../../styles'

export interface IFooterNav {
  elementList: Array<{
    title: string
    link: string
    color?: string
    target?: string
    encoded?: boolean
  }>
  wordings?: {
    choices: string
    mention: string
    accessibilityAlt: string
    accessibilityTitle: string
  }
}

export function FooterNav({ wordings, elementList }: IFooterNav): JSX.Element {
  const { asPath } = useRouter()
  const { isTagCommanderReady, hit } = useContext(TagCommanderContext)
  const openPrivacyCenter = (): void => window?.tC?.privacyCenter?.showPrivacyCenter()
  const homeForYouClass = isPathHomeForYou(asPath) ? ' FooterNav__ForYou' : ''

  const handleOnClick = function (title: string) {
    if (isTagCommanderReady) {
      hit(
        {
          screen_clickableElementName: `footer_${slugify(title, { lower: true })}`,
        },
        { isClickEvent: true },
      )
    }
  }

  return (
    <>
      <section className={`FooterNav__Container${homeForYouClass}`}>
        <div className="FooterNav">
          <div className="FooterNav__Logo">
            <TF1InfoLogo size={200} />
          </div>
          <div className="FooterNav__Link flex justify-center">
            {elementList.map(({ title, link, target, encoded }, index) => (
              <Link
                url={encoded ? '#' : link}
                target={encoded ? undefined : target}
                key={index}
                onClick={() => {
                  handleOnClick(title)
                  if (encoded) {
                    target === '_blank' ? window.open(link, '_blank') : window.location.assign(link)
                  }
                }}
              >
                {title}
              </Link>
            ))}
            <button
              type="button"
              onClick={() => {
                handleOnClick(wordings?.choices || '')
                openPrivacyCenter()
              }}
              data-testid="btn-privacy-center"
            >
              {wordings?.choices || ''}
            </button>
          </div>
        </div>
        <div className="FooterNav__Accessibility flex justify-center">
          <FacilitiButton alt={wordings?.accessibilityAlt} title={wordings?.accessibilityTitle} />
        </div>
        <div className="FooterNav__Mention">
          <span>{wordings?.mention + new Date().getFullYear()}</span>
        </div>
      </section>

      <style jsx>{`
        .FooterNav__Container {
          background-color: var(--color-deepBlue);
          text-align: center;
        }

        .FooterNav__ForYou {
          background-color: var(--color-darkestBlue);
        }

        .FooterNav {
          position: relative;
          width: 100%;
          display: grid;
          grid-template-rows: max-content auto;
          align-items: center;
        }

        .FooterNav :global(a),
        .FooterNav button {
          color: var(--color-midBlue);
          padding: 5px 10px;
          ${mixins.fonts.base.small}
        }

        .FooterNav button {
          cursor: pointer;
          border: none;
          background-color: transparent;
        }

        .FooterNav__Link {
          flex-wrap: wrap;
          text-align: center;
          margin: 0 30px;
          padding-bottom: 16px;
        }

        .FooterNav__Logo {
          padding: 33px 0 32px 5px;
        }

        .FooterNav__Mention {
          padding-bottom: 27px;
          color: var(--color-white);
          font-weight: 100;
        }

        .FooterNav__Accessibility {
          padding-bottom: 53px;
        }

        .FooterNav__Accessibility > :global(button) {
          color: var(--color-white);
          font-weight: 800;
          font-size: 11px;
        }

        @media (max-width: ${theme.breakpoints.sm}px) {
          .FooterNav__Link {
            justify-content: flex-start;
            text-align: start;
          }
        }

        @media ${mixins.mediaQuery.tablet} {
          .FooterNav__Logo {
            padding: 43px 0 36px 5px;
          }
        }
      `}</style>
    </>
  )
}
