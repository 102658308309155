import type { ComponentType, ReactNode, Context } from 'react'
import type { PageType } from '../types/page'

import { createContext, useState } from 'react'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'
import { PAGE_TYPE_ACCOUNT } from '../constants/page'

const NotificationsModalComponent: ComponentType = dynamic(() =>
  import('../components/organisms/NotificationsModal').then((module) => module.NotificationsModal),
)

export interface INotificationsModalProps {
  isOpen: boolean
  setIsOpenNotificationsModal: (isOpen?: boolean) => void
}
export type INotificationsModalContext = Context<INotificationsModalProps>

export const NotificationsModalContext: INotificationsModalContext = createContext({
  isOpen: false,
  setIsOpenNotificationsModal: () => null,
})

export function NotificationsModal({
  isVideo,
  type,
  children,
}: {
  isVideo: boolean
  type: PageType
  children?: ReactNode
}): JSX.Element {
  const isAmp = useAmp()
  const [isOpen, setIsOpen] = useState(false)

  const setIsOpenNotificationsModal = (value) => {
    setIsOpen(value !== undefined ? value : !isOpen)
  }

  if (isAmp || isVideo || type === PAGE_TYPE_ACCOUNT) return <>{children}</>

  return (
    <NotificationsModalContext.Provider
      value={{
        isOpen,
        setIsOpenNotificationsModal,
      }}
    >
      <NotificationsModalComponent />
      {children}
    </NotificationsModalContext.Provider>
  )
}
