import type { ReactNode, Context } from 'react'
import type { PageType } from '../types/page'

import { createContext, useState } from 'react'
import { PAGE_TYPE_ACCOUNT } from '../constants/page'

export interface IMenuProps {
  isOpen: boolean
  setIsOpenMenu: (isOpen?: boolean) => void
}
export type IMenuContext = Context<IMenuProps>

export const MenuContext: IMenuContext = createContext({
  isOpen: false,
  setIsOpenMenu: () => null,
})

export function Menu({ type, children }: { type: PageType; children?: ReactNode }): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)

  const setIsOpenMenu = (value) => {
    setIsOpen(value !== undefined ? value : !isOpen)
  }

  if (type === PAGE_TYPE_ACCOUNT) return <>{children}</>

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setIsOpenMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}
