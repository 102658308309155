import { stringToId } from '../../../helpers/stringToId'
import { LinkArticle } from '../LinkArticle'

export interface IAllNewsItem {
  date: string
  link: string
  title: string
  index: number
  hidden?: boolean
  type?: string
  hasMultiLive?: boolean
  onClick?: () => void
}

export function AllNewsItem({
  date,
  link,
  title = '',
  index,
  hidden,
  type,
  hasMultiLive,
  onClick,
}: IAllNewsItem): JSX.Element {
  return (
    <>
      <li
        key={stringToId(title)}
        className={`AllNewsItem ${hidden ? 'AllNewsItem__Hidden' : ''}`}
        data-testid="AllNewsItem"
      >
        <LinkArticle
          date={date}
          index={index + 1}
          link={link}
          puceStyle={['italic']}
          title={title}
          target="_blank"
          type={type}
          hasMultiLive={hasMultiLive}
          onClick={onClick}
        />
      </li>

      <style jsx>{`
        .AllNewsItem {
          padding: 8px 0;
        }

        .AllNewsItem__Hidden {
          display: none;
          visibility: hidden;
        }
      `}</style>
    </>
  )
}
