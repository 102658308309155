import type { BlockData } from '../../templates/Block'
import { useContext, useEffect, useId, useRef, useState } from 'react'
import { useAmp } from 'next/amp'
import { Headline } from '../../atoms/Headline'
import { ShowMoreButton } from '../../atoms/ShowMoreButton'
import { theme } from '../../../styles'
import { AllNewsItem } from '../../atoms/AllNewsItem'
import { stringToId } from '../../../helpers/stringToId'
import { TagCommanderContext } from '../../../contexts/tagcommander'

const allNewsId = 'all-news-id'

export function AllNews({
  title,
  mostRead,
  newsFeed,
  trendings,
}: BlockData<'all-news'>): JSX.Element {
  const generatedId = useId()
  // document.querySelector doesn't work with : in the id
  const id = generatedId.replaceAll(':', '')
  const isAmp = useAmp()
  const { hit } = useContext(TagCommanderContext)
  const threads = [mostRead, newsFeed, trendings].filter((v) => v)
  const [tabIndex, tabIndexSet] = useState(0)
  const [hiddenTabs, hiddenTabsSet] = useState(true)

  const tablistRef = useRef<HTMLDivElement>()

  const handleShowMore = () => {
    hiddenTabsSet(!hiddenTabs)
  }
  const handleTabIndex = (index) => () => tabIndexSet(index)

  const handleKeydown = function ({ tabs, tab }) {
    return (e) => {
      const firstTab = tabs[0]
      const lastTab = tabs[tabs.length - 1]
      const showMoreButton = document.querySelector(`#AllNews__ShowMoreButton${id}`)

      if ((e.keyCode || e.which) === 38 || (e.keyCode || e.which) === 37) {
        if (tab == firstTab) {
          e.preventDefault()
          lastTab?.focus()
        } else if (showMoreButton && showMoreButton === tab) {
          e.preventDefault()
          const tabToBeFocus = tabs[tabIndex + 1]
          tabToBeFocus?.focus?.()
        } else {
          e.preventDefault()
          const tabToBeFocus = tabs[tabIndex - 1]
          tabToBeFocus?.focus()
        }
      } else if ((e.keyCode || e.which) === 40 || (e.keyCode || e.which) === 39) {
        if (tab == lastTab) {
          e.preventDefault()
          firstTab?.focus()
        } else {
          e.preventDefault()
          const tabToBeFocus = tabs[tabIndex + 1]
          tabToBeFocus?.focus()
        }
      }
    }
  }

  const handleOnClick = () => {
    hit(
      {
        screen_clickableElementName: `bloc_tout-tf1info`,
      },
      { isClickEvent: true },
    )
  }

  useEffect(() => {
    if (tablistRef.current) {
      const tabs = Array.from(tablistRef.current.children)

      tabs.forEach((tab) => {
        tab.addEventListener('keydown', handleKeydown({ tab, tabs }))
      })

      return () => {
        tabs.forEach((tab) => {
          tab.removeEventListener('keydown', handleKeydown({ tab, tabs }))
        })
      }
    }
  }, [])

  if (!threads.length) {
    return null
  }

  return (
    <>
      <section
        data-module="all-news"
        className="AllNews flex flex-column justify-center"
        aria-labelledby={id}
      >
        <Headline htmlText={title} id={id} />
        {isAmp &&
          threads.map((_, index) => (
            <input
              key={index}
              type="radio"
              className="AllNews__Input"
              id={`all-news-thread-${index}`}
              name={allNewsId}
              value={index}
              defaultChecked={index === 0}
            />
          ))}
        <div role="tablist" className="AllNews__Filter flex items-center" ref={tablistRef}>
          {threads.map((thread, index) =>
            isAmp ? (
              <label
                key={index}
                className="AllNews__Filter__Button flex items-center justify-center"
                id={`AllNews__Filter__Button_${index}`}
                htmlFor={`all-news-thread-${index}`}
              >
                {thread.title}
              </label>
            ) : (
              <button
                className={`AllNews__Filter__Button${
                  tabIndex === index ? ' AllNews__Filter__Button--active' : ''
                } flex items-center justify-center`}
                role="tab"
                id={`onglet-${index + 1}`}
                key={`onglet-${index + 1}`}
                tabIndex={index === tabIndex ? undefined : -1}
                aria-selected={index === tabIndex ? 'true' : 'false'}
                aria-controls={`AllNews__List__${index + 1}`}
                onClick={handleTabIndex(index)}
              >
                {thread.title}
              </button>
            ),
          )}
        </div>
        {isAmp && <input type="checkbox" id={allNewsId} className="AllNews__Input" />}
        {threads.map(({ elementList }, index) =>
          elementList.length ? (
            <ul
              key={index}
              className={`AllNews__List${
                tabIndex === index && !isAmp ? ' AllNews__List--visible' : ''
              }`}
              id={`AllNews__List__${index}`}
              role="tabpanel"
              aria-labelledby={`onglet-${index + 1}`}
              tabIndex={0}
            >
              {elementList?.map((item, index) => (
                <AllNewsItem
                  key={stringToId(item.title)}
                  {...item}
                  index={index}
                  hidden={(!isAmp && index > 4 && hiddenTabs) || (isAmp && index > 4)}
                  onClick={handleOnClick}
                />
              ))}
              {hiddenTabs && (
                <ShowMoreButton
                  title="Voir plus d'actualités"
                  id={`AllNews__ShowMoreButton${id}-${index}`}
                  onClick={isAmp ? undefined : handleShowMore}
                  htmlFor={allNewsId}
                />
              )}
            </ul>
          ) : null,
        )}
      </section>
      <style jsx>{`
        #${allNewsId}:checked ~ .AllNews__List > :global(.AllNewsItem__Hidden),
        .AllNews__List :global(:nth-child(6) ~ .ShowMoreButton) {
          display: flex;
          visibility: visible;
        }

        #${allNewsId}:checked :global(~ .AllNews__List > .ShowMoreButton) {
          display: none;
          visibility: hidden;
        }
      `}</style>
      <style jsx>{`
        #${allNewsId}:checked ~ .AllNews__List > :global(.AllNewsItem__Hidden),
        .AllNews__List :global(:nth-child(6) ~ .ShowMoreButton) {
          display: flex;
          visibility: visible;
        }

        #${allNewsId}:checked :global(~ .AllNews__List > .ShowMoreButton) {
          display: none;
          visibility: hidden;
        }
      `}</style>
      <style jsx>{`
        .AllNews {
          margin: 30px auto 60px;
          padding: ${theme.block.marginLR}px;
          max-width: ${theme.layout.desktop.body.width}px;
        }

        .AllNews :global(.LinkArticle) {
          color: ${theme.cssVars.fontColor};
        }

        .AllNews__List {
          list-style: none;
          padding: 0;

          display: none;
          visibility: hidden;
        }

        .AllNews :global(.ShowMoreButton) {
          display: none;
          visibility: hidden;
          margin-top: 20px;
        }

        .AllNews__Input {
          display: none;
          visibility: hidden;
        }

        .AllNews__Filter {
          justify-content: space-evenly;
          padding: 20px 0;
          margin: 30px 0;
        }

        .AllNews__Filter__Button {
          background: transparent;
          border-radius: 24px;
          border-width: 0;
          color: ${theme.cssVars.fontColor};
          cursor: pointer;
          height: 32px;
          padding: 0 12px 0;

          font-style: normal;
          font-weight: 900;
          font-size: 14px;
          text-transform: uppercase;
          white-space: nowrap;
        }

        label.AllNews__Filter__Button {
          padding-top: 2px;
        }

        #all-news-thread-0:checked ~ .AllNews__Filter #AllNews__Filter__Button_0,
        #all-news-thread-1:checked ~ .AllNews__Filter #AllNews__Filter__Button_1,
        #all-news-thread-2:checked ~ .AllNews__Filter #AllNews__Filter__Button_2,
        .AllNews__Filter__Button--active {
          background: ${theme.colors.focusBlue};
          color: ${theme.colors.deepBlue};
        }

        #all-news-thread-0:checked ~ #AllNews__List__0,
        #all-news-thread-1:checked ~ #AllNews__List__1,
        #all-news-thread-2:checked ~ #AllNews__List__2,
        .AllNews__List--visible {
          display: block;
          visibility: visible;
        }

        .AllNews__List :global(~ .ShowMoreButton) {
          display: none;
          visibility: hidden;
        }
      `}</style>
    </>
  )
}
