import type { BlockTypes } from '@etf1-interne/tf1info_types_news'

import { useRouter } from 'next/router'
import { SVGIcon } from '../SVGIcon'
import { useViewport } from '../../../hook/useViewport'
import { mixins, theme } from '../../../styles'
import { MENU_SECONDARY_ID } from '../../../constants/components'

function Item({
  link,
  title,
  color,
  picto,
  isHighlight,
}: BlockTypes['menu-nav']['data']['elementList'][number] & {
  isHighlight: boolean
}): JSX.Element {
  const ItemComponent = isHighlight ? 'span' : 'a'
  const itemComponentProps = isHighlight ? {} : { href: link }
  return (
    <>
      <li
        className="MenuTabBar__Item flex flex-column justify-center items-center"
        aria-current={isHighlight ? 'true' : undefined}
      >
        {picto ? (
          <SVGIcon
            name={picto}
            secondaryColor={color}
            primaryColor={theme.cssVars.white}
            width="24px"
            height="24px"
          />
        ) : null}
        <ItemComponent
          {...itemComponentProps}
          className="MenuTabBar__Item__Link flex flex-column justify-center items-center"
        >
          {title}
        </ItemComponent>
      </li>
      <style jsx>{`
        .MenuTabBar__Item {
          opacity: ${isHighlight ? 1 : 0.4};
        }
      `}</style>
      <style jsx>{`
        .MenuTabBar__Item {
          width: 65px;
          gap: 4px;
          flex-shrink: 0;
          position: relative;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%;
          color: ${theme.cssVars.white};
          cursor: pointer;
        }

        .MenuTabBar__Item:hover {
          opacity: 1;
        }

        .MenuTabBar__Item * {
          text-align: center;
          white-space: nowrap;
        }

        .MenuTabBar__Item__Link {
          text-decoration: none;
        }

        .MenuTabBar__Item__Link:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
        }
      `}</style>
    </>
  )
}

export function MenuTabBar({ elementList }: BlockTypes['menu-nav']['data']): JSX.Element {
  const router = useRouter()
  const { isMobile } = useViewport()
  return (
    <>
      <nav
        role="navigation"
        aria-label="Menu Secondaire"
        className="MenuTabBar"
        id={isMobile ? MENU_SECONDARY_ID : undefined}
        tabIndex={-1}
      >
        <ul className="MenuTabBar__List">
          {elementList.map((element, index) => {
            const { link, title, color, picto } = element
            return (
              <Item
                key={index}
                link={link}
                title={title}
                color={color}
                picto={picto}
                isHighlight={router.asPath === link}
              />
            )
          })}
        </ul>
      </nav>

      <style jsx>{`
        .MenuTabBar {
          position: fixed;
          bottom: 0;
          width: 100%;
          overflow: hidden;
          border-top: 1px solid ${theme.cssVars.darkBlue};
          background: ${theme.cssVars.blurDarkestBlue};
          backdrop-filter: blur(5px);
          z-index: 50;
          padding: 12px 16px 24px 16px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .MenuTabBar {
            display: none;
          }
        }

        .MenuTabBar__List {
          display: grid;
          grid-auto-flow: column;
          list-style: none;
          margin: 0;
          padding: 0;
          height: 100%;
          justify-content: space-evenly;
        }
      `}</style>
    </>
  )
}
