import type { ComponentType } from 'react'
import type { IPage } from '../../../types/page'
import type { ISideBar } from '../SideBar'
import type { ISEOBreadcrumb } from '../../atoms/SEOBreadcrumb'
import type { ISEOAuthor } from '../../atoms/SEOAuthor'
import type { ISEOPersonality } from '../../atoms/SEOPersonality'
import type { IMenu } from '../../atoms/Menu'
import type { IBlock } from '../Block'

import { useContext, useEffect } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'
import packageData from '../../../package.json'
import configuration from '../../../config'
import { BlockList } from '../BlockList'
import { CarouselFullScreenContext } from '../../../contexts/CarouselFullScreenContext'
import { Menu as MenuContext } from '../../../contexts/MenuContext'
import { NotificationsModal } from '../../../contexts/NotificationsModalContext'
import { PictureFullScreenContext } from '../../../contexts/PictureFullScreenContext'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { isPathLive, isPathHome, isPathHomeForYou, isPathEmission } from '../../../helpers/client'
import { ViewportCondition } from '../../atoms/ViewportCondition/ViewportCondition'
import {
  PAGE_SUBTYPE_CATEGORY,
  PAGE_SUBTYPE_PERSONALITY,
  PAGE_TYPE_AUTHOR,
  PAGE_TYPE_CONTENT,
  PAGE_TYPE_TAG,
  PAGE_SUBTYPE_VIDEO,
  PAGE_TYPE_ACCOUNT,
} from '../../../constants/page'
import { AMPTagCommander } from '../../atoms/AMPTagCommander'
import {
  MAIN_ID,
  MAIN_HOMEFORYOU_ID,
  MENU_MAIN_ID,
  MENU_ID,
  SEARCH_ID,
  FOOTER_ID,
  MENU_SECONDARY_ID,
} from '../../../constants/components'
import { mixins, theme } from '../../../styles'
import { eraseCookie, getCookie } from '../../../helpers/cookies'

const Menu: ComponentType<IMenu> = dynamic(() =>
  import('../../atoms/Menu').then((module) => module.Menu),
)
const SideBar: ComponentType<ISideBar> = dynamic(() =>
  import('../SideBar').then((module) => module.SideBar),
)
const AMPCmp: ComponentType = dynamic(() =>
  import('../../atoms/AMPCmp').then((module) => module.AMPCmp),
)
const AMPHubvisor: ComponentType = dynamic(() =>
  import('../../atoms/AMPHubvisor').then((module) => module.AMPHubvisor),
)
const SEOBreadcrumb: ComponentType<ISEOBreadcrumb> = dynamic(() =>
  import('../../atoms/SEOBreadcrumb').then((module) => module.SEOBreadcrumb),
)
const SEOAuthor: ComponentType<ISEOAuthor> = dynamic(() =>
  import('../../atoms/SEOAuthor').then((module) => module.SEOAuthor),
)

const SEOPersonality: ComponentType<ISEOPersonality> = dynamic(() =>
  import('../../atoms/SEOPersonality').then((module) => module.SEOPersonality),
)

const DESKTOP_BODY_WIDTH = theme.layout.desktop.body.width + theme.block.marginLR * 2

export function PageTemplate({ page, type, subtype, version, generatedDate }: IPage): JSX.Element {
  const { id, author = {}, category, h1, seo, url } = page || {}

  const { asPath } = useRouter()
  const isAmp = useAmp()
  const { isTagCommanderReady, hit } = useContext(TagCommanderContext)

  const mainSection = (page.data.find((section) => section.key == 'main')?.data || []) as any
  const headerSection = page.data.find((section) => section.key == 'header') as any
  const footerSection = page.data.find((section) => section.key == 'footer')?.data as any
  const bodyHeaderSection = (mainSection.find((section) => section.key == 'body-header')?.data ||
    []) as any
  const bodySection = (mainSection.find((section) => section.key == 'body')?.data || []) as any
  const modules: IBlock[] = [...bodyHeaderSection, ...bodySection]
  const hasLeftSidebar = !!mainSection.find((section) => section.key == 'left-sidebar')
  const hasRightSidebar = !!mainSection.find((section) => section.key == 'right-sidebar')
  const hasMainMenu = page.data.find((section) => section.key == 'menu')?.data?.length > 0
  const hasSecondaryMenu =
    headerSection?.data?.find((block) => block.key == 'header-navigation')?.data?.navigation
      ?.length > 0
  const hasHeaderNav = headerSection?.data?.elementList?.length > 0
  const hasFooter = footerSection?.length > 0

  // Detect if there is a number in the last part of the url (with or without trailing slash)
  const isPaginated = url?.match(/^((?:\/[^/]+)+)\/(\d+)\/?$/)
  const isHomeForYou = isPathHomeForYou(asPath)
  const isLive = isPathLive(asPath)
  const isHome = isPathHome(asPath)
  const isEmission = isPathEmission(asPath)
  const playAlong = getCookie('playAlong')
  const isVideo = subtype === PAGE_SUBTYPE_VIDEO
  const canonicalUrl = `${configuration.domain}${url}`
  const rssCategoryUrl = subtype === PAGE_SUBTYPE_CATEGORY ? page?.url?.split('/')?.[1] || '' : ''
  const Container = type === PAGE_TYPE_CONTENT && !isVideo ? 'article' : undefined

  if (!isVideo && playAlong) {
    eraseCookie('playAlong')
  }

  useEffect(() => {
    if (isTagCommanderReady && subtype !== 'home' && subtype !== 'gigya') {
      hit({})
    }
  }, [isTagCommanderReady])

  // Force the scroll to anchor when we use a _blank link
  // It's a bug of nextjs; issue: https://github.com/vercel/next.js/issues/40985
  useEffect(() => {
    if (location?.hash) {
      const hash = location.hash.replace('#', '')
      const anchorElement = document.getElementById(hash)
      anchorElement?.scrollIntoView()
    }
  }, [])

  useEffect(() => {
    if (location?.hash) {
      const hash = location.hash.replace('#', '')
      const anchorElement = document.getElementById(hash)

      anchorElement?.focus?.({ preventScroll: false })
    }
  }, [asPath])

  const handleOnClickAccessibilityMenu = () => {
    const menu = document.getElementById(`${MENU_ID}`) as HTMLInputElement
    if (menu && !menu?.checked) {
      menu.click()
    }
  }

  return (
    <>
      <Head>
        <meta name="keywords" content={seo?.metaKeywords} />
        {(type === PAGE_TYPE_CONTENT || subtype.indexOf('election') !== -1) &&
        !isAmp &&
        !isVideo ? (
          <link rel="amphtml" href={`${configuration.domain}/amp${url}`} />
        ) : null}
        {rssCategoryUrl ? (
          <link
            rel="alternate"
            type="application/rss+xml"
            title={h1}
            href={`https://www.tf1info.fr/feeds/rss-une.xml?category=/${rssCategoryUrl}`}
          />
        ) : null}
        {isAmp && <meta name="amp-consent-blocking" content="" />}
      </Head>
      <nav role="navigation" aria-label="Accès rapide" className="quickAccess">
        <ul id="evitement">
          <li>
            <a href={`#${isHomeForYou ? MAIN_HOMEFORYOU_ID : MAIN_ID}`}>
              Aller au contenu principal
            </a>
          </li>
          {hasHeaderNav && (
            <li>
              <a href={`#${MENU_MAIN_ID}`}>Aller au menu principal</a>
            </li>
          )}
          {hasMainMenu && (
            <li>
              <a href={`#${SEARCH_ID}`} onClick={handleOnClickAccessibilityMenu}>
                Aller à la recherche des programmes
              </a>
            </li>
          )}
          {hasSecondaryMenu && (
            <li>
              <a href={`#${MENU_SECONDARY_ID}`}>Aller au menu secondaire</a>
            </li>
          )}
          {hasFooter && (
            <li>
              <a href={`#${FOOTER_ID}`}>Aller au pied de page</a>
            </li>
          )}
        </ul>
      </nav>
      {isAmp && (
        <>
          <AMPCmp />
          <AMPHubvisor />
          <AMPTagCommander url={`${configuration.domain}${url}`} tms={page?.tms} />
        </>
      )}

      {subtype !== 'home' && (
        <SEOBreadcrumb
          category={category?.name}
          url={url}
          title={h1}
          elementList={seo?.breadcrumb}
        />
      )}
      {type === PAGE_TYPE_AUTHOR && !isPaginated ? (
        <SEOAuthor {...author} url={canonicalUrl} modules={modules} />
      ) : null}
      {type === PAGE_TYPE_TAG && subtype === PAGE_SUBTYPE_PERSONALITY && !isPaginated ? (
        <SEOPersonality
          data={{
            ...(bodyHeaderSection?.find((data) => data?.key === 'personality-header')?.data || {}),
            details: bodyHeaderSection?.find((data) => data?.key === 'personality-details')?.data,
          }}
          url={canonicalUrl}
        />
      ) : null}
      <div
        dangerouslySetInnerHTML={{
          __html: `<!-- Version ${packageData.version}, API v. ${version}, API ${generatedDate} -->
                   <!-- ${type}Id=${id || ''} -->`,
        }}
      />
      <PictureFullScreenContext>
        <CarouselFullScreenContext page={page}>
          <NotificationsModal type={type} isVideo={isVideo}>
            <MenuContext type={type}>
              {type !== PAGE_TYPE_ACCOUNT ? (
                <BlockList name="header" data={page.data as any} />
              ) : null}

              {type !== PAGE_TYPE_ACCOUNT ? (
                <Menu>
                  <BlockList name="menu" data={page.data as any} />
                </Menu>
              ) : null}
              {!isHomeForYou && bodyHeaderSection ? (
                <div
                  data-module="body-header"
                  id="body-header"
                  className={`FullWidth${isLive ? ' BodyHeaderLive' : ''}`}
                >
                  <BlockList name="body-header" data={mainSection} />
                </div>
              ) : null}
              {!isHomeForYou ? (
                hasLeftSidebar || hasRightSidebar ? (
                  <main
                    aria-label="Contenu principal"
                    id={`${MAIN_ID}`}
                    role="main"
                    tabIndex={-1}
                    className="PageTemplate__Main flex"
                    data-module="main"
                  >
                    <section
                      className="LeftSidebar flex items-end justify-end"
                      data-module="main.leftSidebar"
                    >
                      <div className="LeftSidebar__container">
                        <BlockList name="left-sidebar" data={mainSection} />
                      </div>
                    </section>
                    <section className="Main__Body" data-module="main.body" data-type={type}>
                      <BlockList name="body" data={mainSection} Container={Container} />
                    </section>
                    <section className="RightSidebar" data-module="main.rightSidebar">
                      <div className="RightSidebar__container">
                        <ViewportCondition visibleViewports={[theme.breakpoints.xl]}>
                          <SideBar name="right-sidebar" data={mainSection} />
                        </ViewportCondition>
                      </div>
                    </section>
                  </main>
                ) : (
                  <BlockList name="body" data={mainSection} Container={Container} />
                )
              ) : (
                <BlockList name="for-you" data={page.data as any} />
              )}
              <BlockList name="cookies" data={page.data as any} />

              <footer
                id={FOOTER_ID}
                tabIndex={-1}
                role="contentinfo"
                aria-label="Pied de page"
                className="FullWidth"
              >
                <BlockList name="footer" data={page.data as any} />
              </footer>
            </MenuContext>
          </NotificationsModal>
        </CarouselFullScreenContext>
      </PictureFullScreenContext>

      <style jsx global>{`
        body[data-theme='blue'] {
          background: ${theme.cssVars.bodyBackground};
        }

        body[data-theme='dark'] {
          background: ${theme.cssVars.darkestBlue};
        }

        // In main body on Content page select all article-paragraph
        // excluding paragraph with old brother (select first paragraph)
        .Main__Body[data-type='content']
          > article
          > p[data-module='article-paragraph']:not([data-module='article-paragraph']
            ~ [data-module='article-paragraph']):first-of-type::first-letter {
          float: left;
          font-size: 56px;
          line-height: 46px;
          margin-top: 4px;
          margin-right: 5px;
        }

        @media ${mixins.mediaQuery.desktop} {
          .Main__Body[data-type='content']
            > article
            > p[data-module='article-paragraph']:not([data-module='article-paragraph']
              ~ p[data-module='article-paragraph']):first-of-type::first-letter {
            font-size: 85px;
            line-height: 68px;
            margin-top: 7px;
          }
        }
      `}</style>
      <style jsx>{`
        .Main__Body {
          min-width: ${isEmission ? 'initial' : `min(100vw,${theme.layout.desktop.body.width}px)`};
          max-width: ${isEmission ? 'initial' : `min(100vw,${theme.layout.desktop.body.width}px)`};
        }
      `}</style>
      <style jsx>{`
        #body-header {
          padding-bottom: ${isHome || isVideo ? 0 : '30px'};
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          #body-header {
            padding-bottom: ${isVideo ? 0 : '80px'};
          }
        }
      `}</style>
      <style jsx>{`
        .BodyHeaderLive {
          background-color: ${theme.cssVars.darkestBlue};
        }

        .BodyHeaderLive :global(.CarouselHighlights) {
          background-color: ${theme.colors.deepGrayBlue};
        }
      `}</style>
      <style jsx>{`
        .quickAccess {
          left: -999px;
          position: absolute;
          top: auto;
          width: 1px;
          height: 1px;
          overflow: hidden;
          z-index: -999;
        }

        .Main__Body {
          min-height: 100vh;
          margin: 0 auto;
        }

        .LeftSidebar,
        .RightSidebar {
          position: relative;
          display: none;
        }

        .LeftSidebar {
          padding-bottom: 30px;
        }

        .LeftSidebar__container,
        .RightSidebar__container {
          max-width: ${theme.layout.desktop.sidebar.width}px;
        }

        .LeftSidebar__container {
          position: sticky;
          bottom: 50px;
          z-index: 10;
        }

        .RightSidebar__container {
          margin-left: 20px;
          margin-right: auto;
          width: 100%;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Main__Body {
            margin: 0 auto;
          }
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .Main__Body {
            margin: 0;
          }
        }

        @media ${mixins.mediaQuery.onlyTabletPaysage} {
          .LeftSidebar {
            display: flex;
            width: calc(100vw / 3);
            padding-right: 30px;
            padding-left: 10px;
          }

          :global(.Main__Body > article) {
            max-width: ${theme.layout.desktop.body.width}px;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .RightSidebar,
          .LeftSidebar {
            display: flex;
            width: 100%;
            max-width: calc(50vw - ${DESKTOP_BODY_WIDTH / 2}px);
          }

          .LeftSidebar {
            padding-right: calc(
              calc(calc(50vw - ${DESKTOP_BODY_WIDTH / 2 + theme.layout.desktop.sidebar.width}px)) -
                var(--player-pip-left)
            );
          }

          .Main__Body {
            min-width: ${DESKTOP_BODY_WIDTH}px;
            max-width: ${DESKTOP_BODY_WIDTH}px;
          }
        }
      `}</style>
    </>
  )
}
